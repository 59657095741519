/* eslint-disable indent */
/* eslint-disable comma-dangle */
/* eslint-disable no-trailing-spaces */

type StaticImages = {
    readonly [fileName: string]: {
        uri?: string;
    };
}

const imageStaticSourcesByFileName: StaticImages = {
    'bgsdsy2gJpmsEd6nfVzHaf.png': { uri: '/images/bgsdsy2gJpmsEd6nfVzHaf.png' },
'dS8soPeAcQaQgNcJjJ5ovi.png': { uri: '/images/dS8soPeAcQaQgNcJjJ5ovi.png' },
'i5gmvGrUr2euJrUjr1W58i.png': { uri: '/images/i5gmvGrUr2euJrUjr1W58i.png' },
'3z9P38qFsRA12uVZPBLXZd.png': { uri: '/images/3z9P38qFsRA12uVZPBLXZd.png' },
'7c9UCTUxetBvvbwcjDnh6v.png': { uri: '/images/7c9UCTUxetBvvbwcjDnh6v.png' },
'34hnwXeRrSS1qgFFWh2oBU.png': { uri: '/images/34hnwXeRrSS1qgFFWh2oBU.png' },
'qH2Wgi1xNsu3swq9WdLPuK.png': { uri: '/images/qH2Wgi1xNsu3swq9WdLPuK.png' },
'6ER3gfhwZaRryarPSErk2j.png': { uri: '/images/6ER3gfhwZaRryarPSErk2j.png' },
'oLT1r9qZG8R1kzgkT5qbGj.png': { uri: '/images/oLT1r9qZG8R1kzgkT5qbGj.png' },
'6dVx7n4YJgPeHRZF8ojacu.png': { uri: '/images/6dVx7n4YJgPeHRZF8ojacu.png' },
'jJ9GeTFK8iKxgx1X4gjukr.jpg': { uri: '/images/jJ9GeTFK8iKxgx1X4gjukr.jpg' },
'5W41gbB79atkrbJTUwmp5H.png': { uri: '/images/5W41gbB79atkrbJTUwmp5H.png' },
'cpPFkpFggaheJEpcFjgxX2.png': { uri: '/images/cpPFkpFggaheJEpcFjgxX2.png' },
'fUsxHpCwTc3UMKz4asTCxz.png': { uri: '/images/fUsxHpCwTc3UMKz4asTCxz.png' },
'5hVX4zJiW5Qx445HvVmQhp.png': { uri: '/images/5hVX4zJiW5Qx445HvVmQhp.png' },
'88jP487mr1zFstweLjYCuZ.png': { uri: '/images/88jP487mr1zFstweLjYCuZ.png' },
'5zV2Uv5CCLiNUiWQg3JuJt.png': { uri: '/images/5zV2Uv5CCLiNUiWQg3JuJt.png' },
'5j8cdfNYtSs9caKhwz9ZKz.png': { uri: '/images/5j8cdfNYtSs9caKhwz9ZKz.png' },
'f68sowWy1C1QUXwGDLRgeA.png': { uri: '/images/f68sowWy1C1QUXwGDLRgeA.png' },
'birsHqYB9H2gVYaMGXvUmw.jpg': { uri: '/images/birsHqYB9H2gVYaMGXvUmw.jpg' },
'9pNGnSqfRm2oL31g4PBbSU.png': { uri: '/images/9pNGnSqfRm2oL31g4PBbSU.png' },
'fWPsPb2ucEzMtG4sMXA8nA.jpg': { uri: '/images/fWPsPb2ucEzMtG4sMXA8nA.jpg' },
'dUBttSBm1Ji83Wf4mewY7r.png': { uri: '/images/dUBttSBm1Ji83Wf4mewY7r.png' },
'uAqYf1gz7RRvxN1Mv4BWHE.jpg': { uri: '/images/uAqYf1gz7RRvxN1Mv4BWHE.jpg' },
'5Zsxdn6X8BP53ZRGFxC8xP.jpg': { uri: '/images/5Zsxdn6X8BP53ZRGFxC8xP.jpg' },
'dDh9Vndyj8LG1xe8HZvvYC.png': { uri: '/images/dDh9Vndyj8LG1xe8HZvvYC.png' },
'9x296FkDFMTwz4cGkSLMM1.png': { uri: '/images/9x296FkDFMTwz4cGkSLMM1.png' },
'6TBfxh47TaPC9umj5EsQiH.jpg': { uri: '/images/6TBfxh47TaPC9umj5EsQiH.jpg' },
'puJYM2XTJjB6izLgA22LMn.jpg': { uri: '/images/puJYM2XTJjB6izLgA22LMn.jpg' }
}

export default imageStaticSourcesByFileName
